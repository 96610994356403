import React from "react";
import RichText from "./RichText";
import YoutubeEmbed from '../../components/basic/YoutubeEmbed'
export default function PoliciesSectionIndiana(props) {
  return (
    <div className="policy-section-indiana">
      <div className="state-products">
        <div className="container-fluid">
          <h1>{props?.data?.content?.Heading}</h1>
          <div className="row no-gutters">
            <div className="col-12 col-sm-12">
              <RichText Description={props?.data?.content?.Paragraph} />
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
